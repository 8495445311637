<template>
  <div class="add-bank">
    <div class="item">
      <div class="head">
        <div class="title">持卡人姓名</div>
      </div>
      <div class="input"><van-field v-model="form.realName" maxlength="16" readonly placeholder="请输入持卡人姓名" /></div>
    </div>
    <div class="item">
      <div class="head">
        <div class="title">卡号</div>
        <div v-if="!showBankName" class="des">*仅支持绑定储蓄卡</div>
        <div v-else class="des" :class="{faild: bankNameStatus === 1}">{{ showBankName }}</div>
      </div>
      <div class="input"><van-field v-model="form.cardNo" placeholder="请输入卡号" type="number" maxlength="23" @input="changeToBank" @blur="getBankName" /></div>
    </div>
    <div class="item">
      <div class="head">
        <div class="title">银行预留电话</div>
      </div>
      <div class="input"><van-field v-model="form.phone" type="number" maxlength="11" placeholder="请输入银行预留电话" /></div>
    </div>
    <div class="item">
      <div class="head">
        <div class="title">验证码</div>
      </div>
      <div class="input">
        <van-field v-model="form.code" placeholder="请输入验证码">
          <template #button>
            <div v-if="sendShow" class="code" @click="getCode">获取验证码</div>
            <div v-else class="code">{{ count }}S</div>
          </template>
        </van-field>
      </div>
    </div>
    <div class="bottom">
      <div class="btn" @click="goAdd">
        <van-button color="#FE9B03" round block>添加</van-button>
      </div>
      <div class="notice">
        添加即代表同意 <span class="copy" @click="goAgreement">鑫猫银行卡协议</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { encryptDes } from '@/utils/des'
import { getCode } from '@/api/auth'
import { bindCard, getBankName } from '@/api/finance'
export default {
  name: 'BankAdd',
  data() {
    return {
      form: {
        realName: '',
        phone: '',
        code: '',
        cardNo: ''
      },
      sendShow: true,
      count: 0,
      time: null,
      showBankName: '',
      bankNameStatus: 0
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  mounted() {
    if (!this.userInfos.realName) {
      this.$dialog.confirm({
        message: '添加银行卡需先完成实名认证，请先完成实名认证',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        confirmButtonColor: '#FF7700'
      })
        .then(async() => {
          this.$router.push({ path: '/userAuthentication', query: { time: new Date().getTime() }})
        })
        .catch(async() => {
          this.$router.go(-1)
        })
      return
    }
    this.time = this.$route.query.time
    this.form.realName = this.userInfos.realName
  },
  activated() {
    console.log(this.time)
    if (this.time) {
      if (this.time !== this.$route.query.time) {
        this.form.realName = ''
        this.form.phone = ''
        this.form.code = ''
        this.form.cardNo = ''
        this.sendShow = true
        this.count = 0
      } else {
        this.time = this.$route.query.time
      }
    }
  },
  methods: {
    changeToBank() {
      if (/\S{5}/.test(this.form.cardNo)) {
        this.form.cardNo = this.form.cardNo.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')
      }
    },
    goAgreement() {
      this.$router.push({ path: '/bankCard' })
    },
    async getCode() {
      if (this.form.phone === '') {
        return this.$toast('请输入银行预留号码！')
      }
      if (this.form.phone.length !== 11) {
        return this.$toast('请输入正确的银行预留号码！')
      }
      const res = await getCode({ mobile: this.form.phone })
      this.$toast({
        message: res.resp_msg,
        duration: 1500
      })
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.sendShow = false
        this.timer = setInterval(() => {
          if (this.count > 1 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.sendShow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    async getBankName() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getBankName(this.form.cardNo.replace(/\s*/g, ''))
      loading.clear()
      this.showBankName = res.resp_msg
      this.bankNameStatus = res.resp_code
    },
    async goAdd() {
      if (!this.form.realName) {
        return this.$toast('请输入持卡人姓名！')
      }
      if (!this.form.cardNo) {
        return this.$toast('请输入卡号！')
      }
      if (!this.form.phone) {
        return this.$toast('请输入银行预留号码！')
      }
      if (!this.form.code) {
        return this.$toast('请输入验证码！')
      }
      const { realName, phone, code, cardNo } = this.form
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await bindCard({
        realName,
        phone,
        code,
        opSource: 4,
        cardNo: encryptDes(cardNo.replace(/\s*/g, '')) // 执行加密
      })
      loading.clear()
      this.$toast({
        message: res.resp_msg || '添加成功',
        duration: 1000,
        onClose: () => {
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

  <style lang="scss" scoped>
  .add-bank {
    .item {
      .head {
        display: flex;
        align-items: center;
        padding: 20px 15px;
        font-size: 14px;
        color:  #979899;
        background: #fcfcfc;
        .title {
          flex: 1;
        }
        .des {
          color: #CACBCC;
        }
        .faild {
          color: #FE9B03;
        }
      }
      .input {
        .code {
          color: #FE9B03;
        }
      }
    }
    .bottom {
      padding: 50px 34px;
      .notice {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 15px;
        font-size: 12px;
        color: #2E3135;
        .icon {
          margin-right: 5px;
          font-size: 18px;
          color: #fa8e0c;
        }
        .copy {
          padding-left: 2px;
          color: #FE9B03;
        }
      }
    }
  }
  </style>

