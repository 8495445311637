import cryptoJs from 'crypto-js'
export function encryptDes(message, key = 'www.com.cn') {
  const keyHex = cryptoJs.enc.Utf8.parse(key)
  const option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 }
  const encrypted = cryptoJs.DES.encrypt(message, keyHex, option)
  return encrypted.toString() // 返回hex格式密文，如需返回base64格式：encrypted.toString()
}

export function decryptDes(message, key = 'www.com.cn') {
  const keyHex = cryptoJs.enc.Utf8.parse(key)
  const decrypted = cryptoJs.DES.decrypt(
    {
      ciphertext: cryptoJs.enc.Hex.parse(message)
    }, // 若message是base64格式，则无需转16进制hex，直接传入message即可
    keyHex,
    {
      mode: cryptoJs.mode.ECB,
      padding: cryptoJs.pad.Pkcs7
    }
  )
  return decrypted.toString(cryptoJs.enc.Utf8)
}
